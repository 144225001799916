import { getFaqApi } from "../constant/Api";
import { myApi } from "./api";

export const faqApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query({
      query: () => ({
        url: getFaqApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status && response?.data?.length > 0
          ? response?.data?.reverse() ?? []
          : [];
      },
      providesTags: (_) => ["faq"],
    }),
  }),
});

export const { useGetFaqQuery } = faqApi;
