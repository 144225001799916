import {
     getTokenSaletimeApi,
  } from "../constant/Api";
  import { myApi } from "./api";
  
  export const tokensaletimeApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getTokenSaleTime: builder.query({
        query: () => ({
          url: getTokenSaletimeApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] : [];
        },
        providesTags: (_) => ["tokensaletime"],
      }),
    }),
  });
  
  export const {
    useGetTokenSaleTimeQuery
  } = tokensaletimeApi;
  