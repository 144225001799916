import { getAiApi } from "../constant/Api";
import { myApi } from "./api";

export const aiApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getAi: builder.query({
      query: () => ({
        url: getAiApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["ai"],
    }),
  }),
});

export const { useGetAiQuery } = aiApi;