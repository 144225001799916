import React, { useState, useEffect } from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  isAddress,
  BrowserProvider,
  Contract,
  formatUnits,
  parseEther,
} from "ethers";
import { buyaddress, buyabi } from "../../constant";
import Modal from "react-bootstrap/Modal";
import copy from "copy-to-clipboard";
import { copyUrl } from "../../constant/BaseUrl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const Refferal = () => {
  const { walletAddress } = useParams();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [inputamount, setinputamount] = useState("");
  const [show, setshow] = React.useState();
  const [copyText, setCopyText] = useState("Invite Link");

  useEffect(() => {
    if (walletAddress) {
      setinputamount(walletAddress);
    }
  }, [walletAddress]);

  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + "referral/" + address);
      setCopyText("Invite Link Copied");
    }
  };
  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  const handlechange = async (e) => {
    setinputamount(e.target.value);
  };
  const showbtn = async () => {
    if (show == true) {
      setshow(false);
    } else {
      setshow(true);
    }
  };

  const buy = async () => {
    try {
    
      const isValidAddress = isAddress(inputamount);
      if (isValidAddress) {
        const provider = await getSignerOrProvider();
        const contract = new Contract(buyaddress, buyabi, provider);
        const transactionResponse  = await contract.setReferer(inputamount);
                              toast.promise(
      transactionResponse.wait(), 
      {
        pending: 'Adding referral to your account ....',
        success: 'Referral added successfully !',
        error: 'Adding Referral Failed!'
      }
    );
            setTimeout(() => {
  window.location.reload();
}, 5000);

      } else {
        toast.dismiss()
        toast.error("This is not a valid address")
      }
    } catch (err) {
      console.error(err);
      if (err.message.includes("You already have a Referal")) {
        toast.dismiss()
        toast.error("You already have a Referral")
      }
                  if (err.message.includes('You cannot refer yourself')) {
        toast.dismiss()
        toast.error("You cannot refer yourself")
      }

    }
  };
  const handleClose = () => {
    setshow(false);
    setCopyText("Invite Link");
  };

  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          <h3 className="mb-3 tgr">Get Referral Link</h3>
          <div className="dash_box ">
            <div className="row">
              <div className="col-md-5 mb-4 mb-md-0">
                <div className=" d-flex   btn_group ">
                  <input
                    className
                    onChange={handlechange}
                    value={inputamount}
                  />

                  <button className="active" onClick={buy} href>
                    Submit Referral
                  </button>
                </div>
              </div>
              <div className=" col-auto  ml-auto">
                {show && (
                  <span className="mr-3 show-wallet-address">
                    {" "}
                    {show ? address : ""}
                  </span>
                )}
                <a className="btn btn-action btn_man_small ml-auto" href>
                  <span onClick={showbtn}>Get Referral Link </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}> </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mr-3 modal-wallet-address">
            {" "}
            {show ? address : ""}
          </div>
          <div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                copyToClipboard(address);
              }}
            >
              {copyText}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
