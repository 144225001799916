import React from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";

export const Community = () => {
  return (
    <>
    <Button/>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          <div className="dash_box text-center pt-5 ">
            <h3 className="mb-3 tgr">Coming Soon..</h3>
          </div>
        </div>
      </div>
    </>
  );
};
