import React from "react";
import { useGetSocialMediaQuery } from "../redux/socialmediaApi";
export const Footer = () => {
  const { data: socialmedia } = useGetSocialMediaQuery();

  return (
    <>
      <section className="contact  footer" id="contact" data-scroll-index={8}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img src="img/logo.png" alt="header-Logo" className="logo" />
              <br />
              <p>
                ChainedX, represents the future of social media powered by
                blockchain technology. By leveraging decentralized networks, we
                aim to revolutionize how users interact, share content, and earn
                rewards securely and transparently. Our platform not only
                enhances privacy and data ownership but also incentivizes active
                participation through tokenomics that reward content creators
                and community contributors. We envision ChainedX becoming a hub
                for innovation, collaboration, and meaningful connections in the
                digital space. Join us in reshaping the landscape of social
                media while embracing the limitless possibilities of blockchain
                technology
              </p>
            </div>
            <div className="col-md-4 pl-md-5">
              <div className="nav_link">
                <h4>Quick links </h4>
                <ul className>
                  <li className>
                    <a className href="#about" data-scroll-nav={2}>
                      About
                    </a>
                  </li>

                  <li className>
                    <a className href="#contact" data-scroll-nav={4}>
                      Tokens
                    </a>
                  </li>
                  <li className>
                    <a className=" active" href="#faq" data-scroll-nav={6}>
                      FAQ
                    </a>
                  </li>
                  <li className>
                    <a className href="#contact" data-scroll-nav={8}>
                      Contacts Us :- <b>support@chainedx.com</b>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3  ">
              <div className="nav_link">
                <h4>Quick links </h4>
                <ul className>
                  {socialmedia &&
                    socialmedia?.map((item, index) => {
                      const updatedTitle = item.title.replace("bi bi-", "");
                      const formattedTitle =
                        updatedTitle.charAt(0).toUpperCase() +
                        updatedTitle.slice(1);
                      return (
                        <li key={index}>
                          <a
                            href={item?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={item.title.replace("bi bi-", "")}
                          >
                            <i className={`${item?.title} mr-2`} />
                            {formattedTitle}
                          </a>
                        </li>
                      );
                    })}

                  {/* <li>
                    <a href="#">
                      <i className="bi bi-telegram mr-2" /> Telegram
                    </a>
                  </li>
                  <li>
                    <a href={socialmedia && socialmedia[0]} target="_blank">
                      <i className="bi bi-twitter mr-2" /> Twitter
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="bi bi-pinterest mr-2" /> Pinterest
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <ul className="social_icon "></ul>
          </div>
        </div>
        <div className="copyright">
          <span> © Copyright 2024 All Rights Reserved.</span>
          <span className="ml-md-5">
            {" "}
            <a href="#">Privacy policy</a>{" "}
            <a href="#"> Terms &amp; Conditions</a>{" "}
          </span>
        </div>
      </section>
    </>
  );
};
