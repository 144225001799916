import { getSocialmediaApi } from "../constant/Api";
import { myApi } from "./api";

export const socialmediaApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getSocialMedia: builder.query({
      query: () => ({
        url: getSocialmediaApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: () => ["socialmedia"],
    }),
  }),
});

export const { useGetSocialMediaQuery } = socialmediaApi;
