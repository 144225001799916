import React from "react";
import { Navbar } from "../../widgets/Navbar";
import { Footer } from "../../widgets/Footer";

import { useGetMissionQuery } from "../../redux/missionApi";
export const Mission = () => {
  const { data: mission } = useGetMissionQuery();
  return (
    <>
      <Navbar />
      <div className="in_page">
        <div className="container">
          {mission && mission[0]?.mission ? (
            <div
              dangerouslySetInnerHTML={{ __html: mission[0]?.mission }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
