import { getMissionApi } from "../constant/Api";
import { myApi } from "./api";

export const missionApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMission: builder.query({
      query: () => ({
        url: getMissionApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["mission"],
    }),
  }),
});

export const { useGetMissionQuery } = missionApi;
