import React from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import { useGetAiQuery } from "../../redux/aiApi";
export const Ai = () => {
  const { data: ai } = useGetAiQuery();

  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          
        <div className="row">
              <div className="col-md-8 m-auto">
                <div className="dash_box  position-relative image_box">
                <img className="po_img" src={ai && ai[0]?.image} alt="" />
           
                <p className="mt-4 position-relative">
                  {ai && ai[0]?.ai ? ai && ai[0]?.ai : "Coming Soon.."}
                </p>
              
          </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
