import React, { useEffect, useState, useMemo } from "react";
import { useGetTokenSaleTimeQuery } from "../redux/tokensaletime";
import { useGetWhitepaperQuery } from "../redux/whitepaperApi";
import { useGetDocumentQuery } from "../redux/documentApi";
import { buyUrl } from "../constant/BaseUrl";

export const Header = () => {
  const { data: whitepaper } = useGetWhitepaperQuery();
  const { data: time } = useGetTokenSaleTimeQuery();
  const { data: record } = useGetDocumentQuery();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const startTime = new Date(time && time[0]?.date_time).getTime();
      let t;
      t = startTime - now;
      if (t <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(t / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((t % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  const memoizedTimeLeft = useMemo(() => {
    return timeLeft;
  }, [timeLeft]);
  let buyTokenLinkShown = false;

  return (
    <>
      <header className="home" id="home" data-scroll-index={1}>
        <div className="banner_content d-flex text-center">
          <div className="container m-auto">
            <div className="row">
              <div className="col-md-10 m-auto ">
                <h1>
                  REDEFINING DIGITAL INTERACTIONS,
                  <br />
                  OWNERSHIP AND PRIVACY
                  <br /> THROUGH <span className="tgr ttu">BLOCKCHAIN</span>
                </h1>
                {time && time[0]?.status == 1 ? (
                  <div className="home_box box mt-md-4 mt-3" data-aos="zoom-in">
                    <h5 className="mb-4">
                      {time && time[0]?.timer_text ? time[0]?.timer_text : ""}
                      <span className="tgr "> September.</span>
                    </h5>
                    <div className="flex_timer">
                      <img src="img/mining.png" alt="hero abstraction" />
                      <section className="pl-lg-4 pt-4 pt-lg-0">
                        <div id="clockdiv">
                          <div className="t_box">
                            <span className="days" id="day">
                              {memoizedTimeLeft.days
                                .toString()
                                .padStart(2, "0")}
                            </span>
                            <div className="smalltext">Days</div>
                          </div>
                          <div className="t_box">
                            <span className="hours" id="hour">
                              {memoizedTimeLeft.hours
                                .toString()
                                .padStart(2, "0")}
                            </span>
                            <div className="smalltext">Hours</div>
                          </div>
                          <div className="t_box">
                            <span className="minutes" id="minute">
                              {memoizedTimeLeft.minutes
                                .toString()
                                .padStart(2, "0")}
                            </span>
                            <div className="smalltext">Minutes</div>
                          </div>
                          <div className="t_box">
                            <span className="seconds" id="second">
                              {memoizedTimeLeft.seconds
                                .toString()
                                .padStart(2, "0")}
                            </span>
                            <div className="smalltext">Seconds</div>
                          </div>
                        </div>
                        {/* <div
                          className="mb-10 mt-3"
                          data-position="flex-between"
                        >
                          <span>Sale Raised</span>
                          <span>Soft-caps</span>
                        </div>
                        <div className="progress_bar_wrapper">
                          <div
                            className="progress_bar"
                            data-percentage="50%"
                            style={{ width: "50%" }}
                          />
                          <div
                            className="progress_pointer"
                            style={{ left: "25%" }}
                          >
                            <div />
                            <span>46,000 BBC</span>
                          </div>
                          <div
                            className="progress_pointer"
                            style={{ left: "75%" }}
                          >
                            <div />
                            <span>96,000 BBC</span>
                          </div>
                        </div> */}
                      </section>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className="login_btns mt-4 pt-md-3 d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <a
                    href={
                      whitepaper && whitepaper[0]?.pdf
                        ? whitepaper && whitepaper[0]?.pdf
                        : "/comming-soon"
                    }
                    className="btn"
                    // download={whitepaper && whitepaper[0]?.pdf}
                    target="_blank"
                  >
                    <i className="bi bi-file-earmark-bar-graph" />{" "}
                    {whitepaper && whitepaper[0]?.pdf
                      ? "Whitepaper"
                      : "White Paper 2.0"}
                  </a>
                  {record &&
                    record.map((item, index) => {
                      if (item?.type === "BuyToken" && !buyTokenLinkShown) {
                        buyTokenLinkShown = true;
                        return (
                          <a
                            key={index}
                            className="nav-link ml-3 border_btn btn"
                            href={item?.url}
                            target="_blank"
                          >
                            <i className="bi bi-database" /> Buy Token
                          </a>
                        );
                      }
                      return null;
                    })}

                  {!buyTokenLinkShown && (
                    <a
                      className="nav-link ml-3 border_btn btn"
                      href={buyUrl}
                      target="_blank"
                    >
                      <i className="bi bi-database" /> Buy Token
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
