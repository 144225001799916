import React from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseEther } from "ethers";
import {
  tokenaddress,
  tokenabi,
  stakingabi,
  stakingaddress,
} from "../../constant";
import { toast } from "react-toastify";
import { Doughnut } from "react-chartjs-2";

export const Dashboard = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tokenbalance, settokenbalance] = React.useState(0);
  const [userList, setUserList] = React.useState([]);
  const [userStake, setuserStake] = React.useState(0);
  const [approval, setapproval] = React.useState(0);
  const [amountinput, setamountinput] = React.useState(0);
  const [depositeAmt, setDepositeAmt] = React.useState(0);
  const [depositeAmtErr, setDepositeAmtErr] = React.useState("");
  const [amountinputErr, setamountinputErr] = React.useState("");
  const [stakeinput, setstakeinput] = React.useState(0);
  const [rewardEs, setrewardEs] = React.useState(0);
  const [totalreturn, settotalreturn] = React.useState(0);
  const [earnrewards, setearnedreward] = React.useState(0);

  const [withDrawfee, setwithdrawfee] = React.useState(0);

  const [changebtn, setchangebtn] = React.useState(false);
  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };

  const fetchtokenBlance = async () => {
    try {
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.balanceOf(address);
      // console.log(formatUnits(balance,18),"bala")
      const fixedBalance = formatUnits(balance, 18);
      const balanceNumber = parseFloat(fixedBalance);
      console.log(balanceNumber.toFixed(3), "token balance");
      settokenbalance(balanceNumber.toFixed(3));
    } catch (err) {
      console.error(err);
    }
  };
  const fetchuserdata = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      const contract = new Contract(stakingaddress, stakingabi, provider);
      const arraylists = await contract.userStakingCount(address);
      const fee = await contract.withdrawfee();
      setwithdrawfee(formatUnits(fee, 18));
      let totalBalance = 0;
      let totalreward = 0;
      for (let i = 0; i < arraylists.length + 1; i++) {
        let num = arraylists[i].toString();
        let intnum = Number(num);
        console.log(intnum);
        const arraylist = await contract.StakingList(intnum);
        setUserList((prevArray) => [...prevArray, arraylist]);
        console.log(formatUnits(arraylist.amount, 18), "number");
        const fixNumber = await formatUnits(arraylist.amount, 18);
        if (arraylist.isWithdrawn == false) {
          totalBalance += Number(fixNumber);
        }
        totalreward += Number(fixNumber);
        const rewrd = (20 * totalreward) / 100;
        setearnedreward(rewrd);

        setuserStake(totalBalance);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const fetchapproval = async () => {
    try {
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const balance = await contract.allowance(address, stakingaddress);
      // console.log(formatUnits(balance,18),"bala")
      const fixedBalance = formatUnits(balance, 18);

      setapproval(fixedBalance);
    } catch (err) {
      console.error(err);
    }
  };

  const handlechange = async (e) => {
    // if (!isConnected) {
    //   toast.error("Please connect wallet")
    // }

    await fetchapproval();

    if (parseFloat(e.target.value) < 0) {
      setDepositeAmtErr("Please enter valid amount");
    } else {
      setDepositeAmtErr("");
      setDepositeAmt(e.target.value);
    }
    if (approval >= parseFloat(e.target.value)) {
      setchangebtn(false);
    } else {
      setchangebtn(true);
    }
  };
  const handlechange1 = async (e) => {
    setamountinput(e.target.value);
    if (parseFloat(e.target.value) < 0) {
      setamountinputErr("Please enter valid amount");
    } else {
      setamountinputErr("");
      setstakeinput(parseFloat(e.target.value) >= 0 ? e.target.value : 0);
      const rewrd = (20 * e.target.value) / 100;

      setrewardEs(rewrd.toFixed(7));

      const totlreturn = parseFloat(e.target.value) + parseFloat(rewrd);
      settotalreturn(totlreturn >= 0 ? parseFloat(totlreturn).toFixed(7) : 0);
    }

    if (approval >= parseFloat(e.target.value)) {
      setchangebtn(false);
    } else {
      setchangebtn(true);
    }
  };

  const approvetoken = async () => {
    try {
      if (!isConnected) {
        toast.dismiss();
        toast.error("Please connect wallet");
        throw Error("User disconnected");
      }
      if (!depositeAmt) {
        setDepositeAmtErr("Amount is required");
      }
      if (depositeAmt < 0) {
        setDepositeAmtErr("Please enter valid amount");
        return false;
      }
      if (tokenbalance < depositeAmt) {
        toast.error("Your token balance is low");

        return false;
      }
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenaddress, tokenabi, provider);
      const transactionResponse = await contract.approve(
        stakingaddress,
        parseEther(depositeAmt.toString())
      );

      toast.promise(transactionResponse.wait(), {
        pending: "Approving...",
        success: "Approval Successful!",
        error: "Approval Failed!",
      });
      setchangebtn(false);
    } catch (err) {
      console.error(err);
    }
  };

  const stake = async () => {
    try {
      if (!isConnected) {
        toast.dismiss();
        toast.error("Please connect wallet");
        throw Error("User disconnected");
      }
      if (depositeAmt < 0) {
        setDepositeAmtErr("Please enter valid amount");
        return false;
      }

      const provider = await getSignerOrProvider();
      const contract = new Contract(stakingaddress, stakingabi, provider);

      const transactionResponse = await contract.stake(
        parseEther(depositeAmt.toString(), 18)
      );
      toast.promise(transactionResponse.wait(), {
        pending: "Depositing Tokens...",
        success: "Deposit Successful!",
        error: "Deposit Failed!",
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (err) {
      if (err.message.includes("transfer amount exceeds balance")) {
        toast.dismiss();
        toast.error("Transfer amount exceeds balance");
      }
      console.error(err);
    }
  };

  const withdrawstake = async (e) => {
    try {
      if (!isConnected) {
        toast.dismiss();
        toast.error("Please connect wallet");
        throw Error("User disconnected");
      }

      const provider = await getSignerOrProvider();
      const contract = new Contract(stakingaddress, stakingabi, provider);
      const fee = await contract.withdrawfee();
      const transactionResponse = await contract.withdraw(e, { value: fee });
      toast.promise(transactionResponse.wait(), {
        pending: "Withdrawing Tokens...",
        success: "Withdraw Successful!",
        error: "Withdraw Failed!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (err) {
      if (err.message.includes("ERC20: transfer amount exceeds balance")) {
        toast.dismiss();
        toast.error("Contract balance exceed transfer");
      }
      if (err.message.includes("Cannot withdraw before 120 days.")) {
        toast.dismiss();
        toast.error("cannot withdraw before 1 minute");
      }
      console.error(err, "message");
    }
  };

  const allfunc = async () => {
    await fetchtokenBlance();
    await fetchuserdata();
    await fetchapproval();
  };
  React.useEffect(() => {
    allfunc();
  }, [address]);
  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          <div className="row row5">
            <div className="col-md-7 mb-4 mb-md-0 ">
              <div className="dash_box1 mb-4">
                <div className="d-flex align-items-center">
                  <div className="img_box">
                    <img alt className="max-h-full" src="img/download.png" />
                  </div>
                  <div className="invited_box">
                    <h3>Staking Status</h3>
                    <p>Staking is not active yet. Stay tuned for updates.</p>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-8 mb-4 mb-md-0">
                  <div className="dash_box ">
                    <h4 className="mb-3">Calculator</h4>

                    <div className="dash_box">
                      <h6 className="d_haddign">Current APY</h6>
                      <div className="value_hadding"> 20% </div>
                    </div>

                    <div className=" deposite_input mt-4">
                      <h6 className="d_haddign">CHDX Amount</h6>
                      <input
                        type="number"
                        onChange={handlechange1}
                        placeholder={0}
                        // value={amountinput == 0 ? "" : amountinput}
                        className="ant-input css-1adbn6x"
                        min="0"
                      />
                      {console.log(amountinputErr, "amountinputErr")}
                      <span style={{ color: "red" }}>{amountinputErr}</span>
                    </div>
                    <div className="border_biv mt-4 mb-4"></div>
                    {/* <div className="border_biv mt-4 mb-4" /> */}
                    <h6 className="d_haddign d-flex mb-3">
                      Your Staking Amount{" "}
                      <span className="ml-auto">{stakeinput} CHDX</span>
                    </h6>
                    <h6 className="d_haddign d-flex mb-3">
                      Rewards estimation{" "}
                      <span className="ml-auto">{rewardEs} CHDX</span>
                    </h6>
                    <h6 className="d_haddign d-flex mb-0">
                      Total returns{" "}
                      <span className="ml-auto">{totalreturn} CHDX</span>
                    </h6>
                  </div>
                </div>
                <div className="col-md-4 mb-4  text-center">
                  <div className="dash_box  mb-2 dlrp ">
                    <h6 className="d_haddign">Your Balance</h6>
                    <div className="value_hadding1">{tokenbalance} CHDX</div>
                  </div>
                  <div className="dash_box  mb-2 dlrp">
                    <h6 className="d_haddign">Current APY</h6>
                    <div className="value_hadding">20%</div>
                  </div>
                  <div className="dash_box   dlrp">
                    <h6 className="d_haddign">Your Staking</h6>
                    <div className="value_hadding">{userStake} CHDX</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-4 mb-md-0">
              <div className="dash_box ">
                <h4 className="mb-3">Earn CHDX</h4>
                <div className="border_biv  mb-4" />
                <h5 className=" d-flex mb-4">
                  APY <span className="ml-auto">20%</span>
                </h5>
                <h6 className="d_haddign d-flex mb-3">
                  Token Balance{" "}
                  <span className="ml-auto"> {tokenbalance} CHDX</span>
                </h6>
                <h6 className="d_haddign d-flex mb-3">
                  {" "}
                  Withdraw Fee{" "}
                  <span className="ml-auto"> {withDrawfee} bnb</span>
                </h6>
                <h6 className="d_haddign d-flex mb-3">
                  {" "}
                  Staking Address{" "}
                  <span className="ml-auto"> Not Active</span>
                </h6>
                <h6 className="d_haddign d-flex mb-4">
                  Pool End{" "}
                  <span className="ml-auto"> ---- </span>
                </h6>
                <h6 className="d_haddign d-flex mb-3 mb-md-4">
                  Total Balance{" "}
                  <span className="ml-auto">
                    {Number(userStake) + Number(tokenbalance)} CHDX
                  </span>
                </h6>
                <h6 className="d_haddign mb-0">Earned Rewards</h6>
                <div className="value_hadding">{earnrewards} CHDX</div>
                <div className="deposite_input mt-3 mt-md-4">
                  <h6 className="d_haddign">Deposit</h6>
                  <input
                    type="number"
                    name="depositAmt"
                    // value={depositeAmt}
                    className="ant-input css-1adbn6x"
                    onChange={handlechange}
                    // min="0"
                  />
                  <span style={{ color: "red" }}>{depositeAmtErr}</span>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="col-12  ">
                    {!changebtn ? (
                      <button onClick={stake} className="btn w100">
                        Deposit
                      </button>
                    ) : (
                      <button onClick={approvetoken} className="btn w100">
                        Approve
                      </button>
                    )}
                  </div>
                  {/* <div className="col-2 text-center">Or
            </div>
             <div className="col-5  pl-0">
              <button type="submit" className="btn border_btn w100" data-toggle="modal" data-target="#Withdraw" >Withdraw</button>
            </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-8 mb-4 ">
              <ul className="nav tab_das mb-4">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#Withdraw"
                  >
                    Withdraw
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link " data-toggle="tab" href="#Overview">
                    Overview
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Topholders">
                    Community Shares
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Topskaters">
                    Top Stakers
                  </a>
                </li> */}
              </ul>
              <div className="dash_box">
                <div className="tab-content">
                  <div className="tab-pane active " id="Withdraw">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="ant-table-thead">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Staking ID</th>
                            <th scope="col"> Staked Token </th>
                            <th scope="col">APY</th>
                            <th scope="col">Action </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          {userList.map((item, index) => (
                            <>
                              <tr>
                                <td> {Number(item.id.toString()) - 1}</td>
                                <td> {item.id.toString()} </td>
                                <td>{formatUnits(item.amount, 18)}</td>
                                <td> 20 % </td>
                                <td>
                                  <button
                                    disabled={item.isWithdrawn ? true : false}
                                    onClick={() =>
                                      withdrawstake(Number(item.id.toString()))
                                    }
                                    className="btn btn_small"
                                  >
                                    Withdrawal
                                  </button>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane  " id="Overview">
                    <div className="table-responsive">
                      <table className="table">
                        <thead className="ant-table-thead">
                          <tr>
                            <th className="ant-table-cell" scope="col">
                              #
                            </th>
                            <th className="ant-table-cell" scope="col">
                              Name
                            </th>
                            <th className="ant-table-cell" scope="col">
                              Rank
                            </th>
                            <th className="ant-table-cell" scope="col">
                              Wallet Address
                            </th>
                            <th className="ant-table-cell" scope="col">
                              Total holding
                            </th>
                            <th className="ant-table-cell" scope="col">
                              Volume
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ant-table-tbody">
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">1</td>
                            <td className="ant-table-cell">
                              <div className="user_img">
                                <img src="img/user.png" alt="chain-dx" />
                                john
                              </div>
                            </td>
                            <td className="ant-table-cell">42</td>
                            <td className="ant-table-cell">
                              10 Downing Street
                            </td>
                            <td className="ant-table-cell">88</td>
                            <td className="ant-table-cell">892742894</td>
                          </tr>
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">2</td>
                            <td className="ant-table-cell">
                              <div className="user_img">
                                <img src="img/user.png" alt="chain-dx" />
                                john
                              </div>
                            </td>
                            <td className="ant-table-cell">42</td>
                            <td className="ant-table-cell">
                              10 Downing Street
                            </td>
                            <td className="ant-table-cell">88</td>
                            <td className="ant-table-cell">892742894</td>
                          </tr>
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">3</td>
                            <td className="ant-table-cell">
                              <div className="user_img">
                                <img src="img/user.png" alt="chain-dx" />
                                john
                              </div>
                            </td>
                            <td className="ant-table-cell">42</td>
                            <td className="ant-table-cell">
                              10 Downing Street
                            </td>
                            <td className="ant-table-cell">88</td>
                            <td className="ant-table-cell">892742894</td>
                          </tr>
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">4</td>
                            <td className="ant-table-cell">
                              <div className="user_img">
                                <img src="img/user.png" alt="chain-dx" />
                                john
                              </div>
                            </td>
                            <td className="ant-table-cell">42</td>
                            <td className="ant-table-cell">
                              10 Downing Street
                            </td>
                            <td className="ant-table-cell">88</td>
                            <td className="ant-table-cell">892742894</td>
                          </tr>
                          <tr className="ant-table-row ant-table-row-level-0">
                            <td className="ant-table-cell">5</td>
                            <td className="ant-table-cell">
                              <div className="user_img">
                                <img src="img/user.png" alt="chain-dx" />
                                john
                              </div>
                            </td>
                            <td className="ant-table-cell">42</td>
                            <td className="ant-table-cell">
                              10 Downing Street
                            </td>
                            <td className="ant-table-cell">88</td>
                            <td className="ant-table-cell">892742894</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="Topholders" className=" tab-pane fade">
                    <br />
                    <h3 className="text-center tgr">Coming Soon</h3>
                  </div>
                  <div id="Topskaters" className=" tab-pane fade">
                    <br />
                    <h3>Menu 2</h3>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="col-md-4 mt-md-5 pt-2">
              <div className="dash_box ">
                <h6 className="d_haddign  mb-1">Balance Usage</h6>
                <span>Calculated by latest activity</span>
                <div className="pt-4 pb-2 text-center px-4.5">
                  <div>
                    <Doughnut
                      className="doughnut-chart"
                      data={{
                        labels: ["Red", "Blue", "Yellow"],
                        datasets: [
                          {
                            label: "# of Votes",
                            data: [30, 25, 10, 35],
                            backgroundColor: [
                              "#F05B47",
                              "#FCAC56",
                              "#59981A",
                              "rgba(255, 255, 255)",
                            ],
                            borderColor: [
                              "#F05B47",
                              "#FCAC56",
                              "#59981A",
                              "rgba(255, 255, 255, 0)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        cutout: "80%", // Adjust this value to control the thickness
                        plugins: {
                          legend: {
                            display: true,
                            position: "bottom",
                          },
                          tooltip: {
                            enabled: false,
                          },
                        },
                      }}
                      plugins={[
                        {
                          id: "drawСircles",
                          afterUpdate(chart) {
                            const arcs = chart.getDatasetMeta(0).data;

                            arcs.forEach((arc, index) => {
                              arc.round = {
                                x:
                                  (chart.chartArea.left +
                                    chart.chartArea.right) /
                                  2,
                                y:
                                  (chart.chartArea.top +
                                    chart.chartArea.bottom) /
                                  2,
                                radius: (arc.outerRadius + arc.innerRadius) / 2,
                                thickness:
                                  index < arcs.length - 1
                                    ? (arc.outerRadius - arc.innerRadius) / 2
                                    : (arc.outerRadius - arc.innerRadius) / 4, // Decrease thickness for the last arc
                                backgroundColor: arc.options.backgroundColor,
                                drawCircle: index < arcs.length - 1, // Do not draw circle for the last arc
                              };

                              if (index === arcs.length - 1) {
                                // Modify innerRadius to adjust the thickness of the last arc
                                arc.outerRadius *= 0.937;
                                arc.innerRadius =
                                  arc.outerRadius - arc.round.thickness * 1.6;
                              }
                            });
                          },
                          afterDraw: (chart) => {
                            const { ctx } = chart;

                            const centerX =
                              (chart.chartArea.left + chart.chartArea.right) /
                              2;
                            const centerY =
                              (chart.chartArea.top + chart.chartArea.bottom) /
                              2;

                            // Custom text based on API data
                            const text1 = `Your Staking`;
                            const text2 = `${userStake} CHDX`;
                            const text3 = `%20`;

                            ctx.fillStyle = "#fff"; // Text color
                            ctx.font = "500 12px Montserrat"; // Text font
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";

                            // Display first value
                            ctx.fillText(text1, centerX, centerY - 10);

                            // Display second value
                            ctx.font = "600 18px Montserrat"; // Text font for second value
                            ctx.fillText(text2, centerX, centerY + 10);

                            // Display third value
                            ctx.fillStyle = "#14C47A";
                            ctx.font = "500 13px Montserrat"; // Text font for third value
                            ctx.fillText(text3, centerX, centerY + 30);

                            chart.getDatasetMeta(0).data.forEach((arc) => {
                              if (!arc.round.drawCircle) return;

                              const endAngle = Math.PI / 2 - arc.endAngle;

                              ctx.save();
                              ctx.translate(arc.round.x, arc.round.y);
                              ctx.fillStyle = arc.options.backgroundColor;
                              ctx.beginPath();
                              ctx.arc(
                                arc.round.radius * Math.sin(endAngle),
                                arc.round.radius * Math.cos(endAngle),
                                arc.round.thickness,
                                0,
                                2 * Math.PI
                              );
                              ctx.closePath();
                              ctx.fill();
                              ctx.restore();
                            });
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="value_hadding mt-4">{tokenbalance} CHDX</div>
                  <div className>Wallet Balance</div>
                </div>
                <button type="submit" className="btn   mt-3 w100">
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
