import React from 'react'
import { useGetSideBarQuery } from '../redux/sidebarApi'

export const Sidebar = () => {
  const {data}=useGetSideBarQuery()
  return (
    <><div className="left_bar">
    <nav className="navbar2 ">
      <div className>
        <a className="navbar-brand p-2 p-lg-4" href="/"><img src="/img/logo.png" alt="header-Logo" className="logo2 w-100" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
          <span className="icon-bar"> <i className="bi bi-justify" /></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav m-auto ">
            {data && data?.map((list)=>{
              return (
                <li className="nav-item">
                <a className=" nav1" href={`/${list.router_name}`}><i className={list.icon} />{list.name}</a>
              </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  </div></>
  )
}
