import {getWhitePaperApi,  } from "../constant/Api";
  import { myApi } from "./api";
  
  export const whitepaperApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getWhitepaper: builder.query({
        query: () => ({
          url: getWhitePaperApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] : [];
        },
        providesTags: (_) => ["whitepaper"],
      }),
    }),
  });
  
  export const {
    useGetWhitepaperQuery,
  } = whitepaperApi;
  