import React from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import { useGetGamingQuery } from "../../redux/gamingApi";
export const Gaming = () => {
  const { data: gaming } = useGetGamingQuery();
  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
       
            <div className="row">
              <div className="col-md-8 m-auto">
              <div className="dash_box   position-relative image_box">
                <img
                  className="po_img"
                  src={gaming && gaming[0]?.image}
                  alt=""
                />
              
                <p className="position-relative mt-4">
                  { gaming && gaming[0]?.gaming ? gaming && gaming[0]?.gaming : "Coming Soon.."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
