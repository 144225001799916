import {
  getPartnershipApi,
} from "../constant/Api";
import { myApi } from "./api";

export const partnershipApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartnership: builder.query({
      query: () => ({
        url: getPartnershipApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["partnership"],
    }),
  }),
});

export const {
  useGetPartnershipQuery
} = partnershipApi;
