import { useState, useEffect, useCallback } from "react";
import { Navbar } from "../widgets/Navbar";
import { Header } from "../widgets/Header";
import { Footer } from "../widgets/Footer";
import Modal from "react-bootstrap/Modal";
import { useGetFaqQuery } from "../redux/faqApi";
import { useGetTeamQuery } from "../redux/teamApi";
import { useGetPartnershipQuery } from "../redux/partnershipApi";
import { useGetAboutQuery } from "../redux/aboutUsApi";
import { useGetVideoQuery } from "../redux/videoApi";
import { useGetAnnouncementQuery } from "../redux/announcementApi";
import { useGetcontractQuery } from "../redux/contractAddressApi";
import { useGetwalletQuery } from "../redux/contractAddressApi";
import { useGetVisionQuery } from "../redux/visionApi";
import { useGetMissionQuery } from "../redux/missionApi";
import { useGetRoadmapQuery } from "../redux/roadmapApi";
import { useGetSocialMediaQuery } from "../redux/socialmediaApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import copy from "copy-to-clipboard";
import AOS from "aos";
import "aos/dist/aos.css";
import { PieChart, Pie, Cell, Sector } from "recharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Tooltip as ReactTooltip } from "react-tooltip";

ChartJS.register(ArcElement, Tooltip, Legend);
const COLORS = [
  "#0088FE",
  "#11B55E",
  "#FFBB28",
  "#FF8042",
  "#c43bc0",
  "#5f04c7",
];
const DATA = [
  { name: "Presale", value: 40 },
  { name: "Liquidity ", value: 10 },
  { name: "Lock", value: 40 },
  { name: "Staking", value: 5 },
  { name: "Operations", value: 3 },
  { name: "Airdrop", value: 2 },
];

export const Home = () => {
  const { data: faq } = useGetFaqQuery();
  const { data: contract } = useGetcontractQuery();
  const { data: wallet } = useGetwalletQuery();
  const { data: aboutUs } = useGetAboutQuery();
  const { data: roadmap } = useGetRoadmapQuery();
  const { data: socialmedia } = useGetSocialMediaQuery();
  const { data: vision } = useGetVisionQuery();

  const { data: mission } = useGetMissionQuery();
  const { data: team } = useGetTeamQuery();
  const { data: partnerShip } = useGetPartnershipQuery();
  const { data: video } = useGetVideoQuery();
  const { data: announcement } = useGetAnnouncementQuery();
  const [show, setShow] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [copyWallet, setCopyWallet] = useState("Copy");
  const [copySmartContract, setCopySmartContract] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [chartColor, setChartColor] = useState(COLORS);
  const [chartData, setChartData] = useState(DATA);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return window.removeEventListener("resize", handleResize);
  }, []);

  const data = {
    labels: [
      "Marketing & General",
      "Pre-ICO ",
      "Mobile Ad Platform",
      " Team & Advisors",
      "Platform Integration",
    ],
    datasets: [
      {
        // label: [
        //   "Marketing & General",
        //   "Pre-ICO ",
        //   "Mobile Ad Platform",
        //   " Team & Advisors",
        //   "Platform Integration",
        // ],
        data: [300, 50, 100, 50, 20],
        backgroundColor: [
          "#0088FE",
          "#00C49F",
          "#FFBB28",
          "#FF8042",
          "#c43bc0",
        ],
        borderColor: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#c43bc0"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: "#fff",
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value / sum) * 100).toFixed(2) + "%";
          return percentage;
        },
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
          size: "16",
        },
      },
    },
  };

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 200,
    });
  }, []);

  const copyToClipboard = (address, type) => {
    if (address) {
      copy(address);
      if (type === "contract") {
        setCopyText("Contract Address Copied");
      } else if (type === "smart") {
        setCopySmartContract("Address Copied");
      } else {
        setCopyWallet("Address Copied");
      }
      setTimeout(() => {
        setCopyText("Copy");
        setCopyWallet("Copy");
        setCopySmartContract(null);
      }, 2000);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const settingss = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
  };
  const settingsForTeam = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    console.log("percent", typeof percent);
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#FACC51"
        >{`${value}%`}</text>
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${Number(percent * 100).toFixed(2)}%`}
        </text> */}
      </g>
    );
  };

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <>
      <Navbar />
      <Header />
      <div>
        <div id="scroll-to-top">
          <i className="bi bi-chevron-up fa-fw" />
        </div>
        <section className="stats p60 position-relative">
          <div className="container">
            <div className="stats_bx" data-aos="flip-up">
              <div className="stats_card aos-init aos-animate ml-auto">
                <div className="stats_card_background" />
                <h3>CHDX</h3>
                <p>Symbol</p>
              </div>
              <div className="stats_card aos-init aos-animate">
                <div className="stats_card_background scb" />
                <h3>1B</h3>
                <p>Total Supply</p>
              </div>
              <div className="stats_card aos-init aos-animate">
                <div className="stats_card_background" />
                <h3>0-3%</h3>
                <p>Max Sell Tax</p>
              </div>
              <div className="stats_card aos-init aos-animate">
                <div className="stats_card_background scb" />
                <h3>0%</h3>
                <p>Buy Tax</p>
              </div>
              <div className="stats_card aos-init aos-animate">
                <div className="stats_card_background" />
                <h3>
                  3% <small>(➡ 🔥)</small>
                </h3>
                <p>Max Sell Tax</p>
              </div>
              <div className="stats_card aos-init aos-animate mr-auto">
                <div className="stats_card_background scb" />
                <h3>BNB</h3>
                <p>Chain</p>
              </div>
            </div>
          </div>
        </section>

        <div className="about p60 position-relative pb-0" data-scroll-index={2}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="about_img text-center">
                  <img
                    src="img/aboutv3.jpg"
                    alt="about"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-7  mt-4 mt-md-0">
                <h2 className=" hadding mb-2 ">
                  About Chained<span className="tgr fwb">X</span>
                </h2>
                <p className>
                  {aboutUs && aboutUs[0]?.about_us}
                  {/* ChainedX is one of thhe most transformative techhnologies
                  since the invention of the Internet. ChainedX stands firmly in
                  support of financial freedom and the liberty that Bitcoin
                  provides globally for anyone to voluntarily participate in a
                  permissionless and decentralized work.{" "} */}
                </p>
                {/* <p>
                  Which empowers people to not be marginalized by governments
                  and financial institutions. Bitcoin is freedom. If you are
                  going to use a passage of Loreum Ipsum, you need to be sure
                  there isn’t anything embarrassing hidden in the middle of the
                  text.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="about p60 position-relative overflow-hidden"
          data-scroll-index={3}
        >
          <div className="container">
            <div className="row mt-md-4">
              <div className="col-md-6 mb-4" data-aos="fade-right">
                <div className="h_box box">
                  <h4 className="tgr">
                    <i className="bi bi-wallet2 mr-2" /> Load BNB into Your
                    Wallet
                  </h4>
                  <p>
                    Ensure you have a supported wallet such as MetaMask or Trust
                    Wallet installed. Purchase BNB from a reputable exchange
                    like Binance and transfer it to your wallet. Confirm the BNB
                    balance in your wallet before proceeding.
                  </p>
                </div>
              </div>
              <div className="col-md-6  mb-4" data-aos="fade-left">
                <div className="h_box box">
                  <h4 className="tgr">
                    <i className="bi bi-file-earmark-text mr-2" /> Import the
                    Contract
                  </h4>
                  <p>
                    Go to the "Import Tokens" section in your wallet and enter
                    the ChainedX token contract address provided on our website.
                    Add the token to your wallet by confirming the import. You
                    should now see the ChainedX token balance in your wallet.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4" data-aos="fade-right">
                <div className="h_box box">
                  <h4 className="tgr">
                    <i className="bi bi-currency-exchange mr-2" /> Buy
                    Tokens(CHDX) on PancakeSwap
                  </h4>
                  <p>
                    Visit PancakeSwap and connect your wallet by clicking the
                    "Connect Wallet" button. Navigate to the "Trade" section and
                    select "Exchange." Enter the ChainedX token contract address
                    to find the token. Enter the amount of BNB you wish to swap
                    for CHDX tokens and confirm the transaction. Your ChainedX
                    tokens will be transferred to your wallet once the
                    transaction is complete.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4" data-aos="fade-left">
                <div className="h_box box">
                  <h4 className="tgr">
                    <i className="bi bi-rocket-takeoff mr-2" /> $CHAIN Token
                  </h4>
                  <p className="tgr mb-0">Launching Soon!</p>
                  <p className="mb-0">
                    Recipient: Holders of CHDX tokens & early investors.
                  </p>
                  <p className="mb-0">
                    Distribution: 5% of $CHAIN tokens will be distributed.
                  </p>
                  <p className="mb-0 tgr">Governance Token:</p>
                  <p className="">
                    Participate in network governance to strengthen and shape
                    the future of <span className="tgr">ChainedX</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*
    <div class="features_section   position-relative p60" data-scroll-index="2">

      <div class="container">
        <h2 class=" hadding mb-3 mb-md-4 text-center">Access The <span class="tgr fwb">Future</span> </h2>
        <div class="row">
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-1.png" alt=" Payment Gateway">
                <h3 class="ml-3">

                  Payment Gateway
                </h3>
              </div>
              <p>Seamlessly accept cryptocurrencies in your online business, expanding payment options for your
                customers.
              </p>

            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-2.png" alt="Interconnected Economies">
                <h3 class="ml-3">
                  DeFi Wallet
                </h3>
              </div>
              <p>
                A secure, non-custodial wallet supporting multiple blockchains, providing you full control over your
                assets.
              </p>

            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-3.png" alt=" Swap Aggregator">
                <h3 class="ml-3">
                  Swap Aggregator
                </h3>
              </div>
              <p> Effortlessly find the most favorable rates by instantly comparing quotes and fees across various
                decentralized exchanges (DEXes).</p>

            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-4.png" alt="Incubation">
                <h3 class="ml-3">
                  AI-Powered P2P Lending
                </h3>
              </div>
              <p>
                Let our powerful AI analyze lending options for you, ensuring you get the best and most profitable
                lending solutions.
              </p>

            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-5.png" alt="Initial Metaverse Offering">
                <h3 class="ml-3">

                  Perpetual Futures & Options
                </h3>
              </div>
              <p>
                Engage in long and put options directly from the App, simplifying your trading experience across
                different assets.
              </p>

            </div>
          </div>
          <div class="col-lg-4 col-sm-6 mb-4">
            <div class="single-features style-two">
              <div class="d-flex align-items-center">
                <img decoding="async" src="img/icon-6.png" alt="Next Tier Metaverse Projects">
                <h3 class="ml-3">
                  Secure Storage
                </h3>
              </div>
              <p>
                We talk about how this approach protects user data and digital resources from data breaches and other
                security threats.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    */}
        <div className="about p60 position-relative bg_light overflow-hidden">
          <div className="container">
            <h2 className=" hadding mb-3 mb-md-5 text-center">
              Mission <span className="tgr fwb">Vision</span>
            </h2>
            <div className="row">
              <div
                className="col-md-4 mb-5 mb-md-4 ml-auto mr-md-4 mb_s mt-5"
                data-aos="fade-right"
              >
                <div className="mv_box">
                  <span className="icon">
                    <i className="bi bi-rocket-takeoff"></i>
                  </span>
                  <h4>Mission </h4>
                  <div className="c_fix mb-4">
                    {mission && mission[0]?.mission ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: mission[0]?.mission,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <a href="/mission" className="tgr">
                    Read More
                  </a>
                </div>
              </div>
              <div
                className="col-md-4 mb-4 mr-auto ml-md-4 mb_s mt-5"
                data-aos="fade-left"
              >
                <div className="mv_box">
                  <span className="icon">
                    <i className="bi bi-eye"></i>
                  </span>
                  <h4>Vision</h4>
                  <div className="c_fix mb-4">
                    {vision && vision[0]?.vision ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: vision[0]?.vision }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <a href="/vision" className="tgr">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="token_distribution" id="token" data-scroll-index={4}>
          <div className="container">
            <h2 className="text-center hadding mt-5">
              Token Sale <span className="tgr fwb"> Proceeds</span>
            </h2>
            <p className="text-center mb-md-5">
              Navigate our crypto ecosystem with a clear view of token
              allocation through our interactive pie chart.
            </p>
            <div className="row align-items-center">
              <div className="col-lg-6 text-center">
                {/* <Doughnut data={data} options={options} /> */}
                <div>
                  <PieChart
                    width={isMobile ? 360 : 500}
                    height={isMobile ? 350 : 450}
                  >
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={chartData}
                      cx={isMobile ? 170 : 250}
                      cy={isMobile ? 160 : 225}
                      innerRadius={isMobile ? 60 : 90}
                      outerRadius={isMobile ? 90 : 160}
                      fill="red"
                      dataKey="value"
                      onMouseEnter={onPieEnter}
                    >
                      {chartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={chartColor[index % chartColor.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
                {/* <ResponsiveContainer width="100%" height={500}>
                 
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={200}
                      fill="#020202"
                      dataKey="value"
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      onMouseEnter={(event, index) => setActiveIndex(index)}
                      onMouseLeave={() => setActiveIndex(-1)}
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer> */}
              </div>
              <div className="col-md-5 ml-auto mt-4 mt-md-0">
                <div class="cube">
                  <div class="active-state"> Presale 40% </div>
                  <div className="default-state">PRESALE</div>
                </div>
                <div class="cube cube3">
                  <div class="active-state">
                    Liquidity 10% (locked for 100 yrs)
                  </div>
                  <div className="default-state">LIQUIDITY</div>
                </div>
                <div class="cube cube2">
                  <div class="active-state"> Lock 40% </div>
                  <div className="default-state">LOCK</div>
                </div>
                <div class="cube cube4">
                  <div class="active-state"> Staking 5% </div>
                  <div className="default-state">STAKING</div>
                </div>
                <div class="cube cube5">
                  <div class="active-state"> Project Operations 3% </div>
                  <div className="default-state">PROJECT OPERATIONS</div>
                </div>
                <div class="cube cube6">
                  <div class="active-state"> Airdrop 2% </div>
                  <div className="default-state">AIRDROP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="roadmap position-relative roadmap_container p60"
          id="token"
          data-scroll-index={5}
        >
          <div className="container">
            <h2 className="text-center  hadding">
              Road<span className="tgr fwb">Map</span>{" "}
            </h2>
            <p className="mb-4 mb-md-5 text-center">
              {/* The use of crypto-currencies has become more widespread, and they
              are now increasingly accepted as a legitimate currency for
              transactions. */}
              {roadmap && roadmap[0]?.roadmap}
            </p>
          </div>
          <div className="roadmap_wrapper">
            <div className="container">
              <div className="roadmap_section" data-aos="fade-up">
                <section className="aos-init aos-animate">
                  <i className="bi bi-megaphone" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase1_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase1}</p>
                </section>
                <section className="aos-init aos-animate">
                  <i className="bi bi-cash-stack" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase2_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase2}</p>
                </section>
                <section className="aos-init aos-animate">
                  <i className="bi bi-globe" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase3_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase3}</p>
                </section>
              </div>
            </div>

            <Slider {...settingss}>
              {partnerShip?.map((list) => {
                return (
                  <div key={list.id}>
                    <img
                      src="img/roadmap_vertical_new.png"
                      alt="chainedx roadmap illustration"
                      className="roadmap_abstract"
                    />
                  </div>
                );
              })}
            </Slider>

            <img
              src="img/roadmap_mob.png"
              alt="chainedx roadmap illustration"
              className="roadmap_abstract_vertical"
            />
            <div className="container">
              <div className="roadmap_section right" data-aos="fade-down">
                <section className="aos-init">
                  <i className="bi bi-arrow-left-right" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase4_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase4}</p>
                </section>
                <section className="aos-init">
                  <i className="bi bi-send-plus" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase5_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase5}</p>
                </section>
                <section className="aos-init">
                  <i className="bi bi-truck" />
                  <b className="mb-10 mt-20">
                    {" "}
                    {roadmap && roadmap[0]?.phase6_month_year}
                  </b>
                  <p>{roadmap && roadmap[0]?.phase6}</p>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="about p60 position-relative bg_light  overflow-hidden">
          <div className="container">
            <h2 className="hadding mb-3 mb-md-5 text-center">
              Smart <span className="tgr fwb">Contracts</span>
            </h2>
            <div className="row">
              <div
                className="col-md-3 mb-5 mb-md-4 ml-auto mr-md-4 mb_s mt-5"
                data-aos="fade-up-right"
              >
                <div className="mv_box d-flex flex-column">
                  <span className="icon">
                    <i class="bi bi-file-earmark-medical"></i>
                  </span>
                  <h4>Smart Contract</h4>
                  <a
                    href={`https://bscscan.com/address/${
                      contract && contract[0]?.smart_contract_address
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p
                      className="wbb"
                      style={{
                        color: "rgb(194, 138, 36)",
                        textDecoration: "underline",
                      }}
                    >
                      {contract?.[0]?.smart_contract_address?.length > 27
                        ? contract?.[0]?.smart_contract_address?.substring(
                            0,
                            15
                          ) +
                          "..." +
                          contract?.[0]?.smart_contract_address?.substring(
                            contract?.[0]?.smart_contract_address?.length - 10
                          )
                        : contract?.[0]?.smart_contract_address}
                    </p>
                  </a>
                  <span
                    className="position-relative overflow-auto"
                    style={{ zIndex: 100 }}
                  >
                    <i
                      class="bi bi-copy"
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                      onClick={(e) => {
                        copyToClipboard(
                          contract[0]?.smart_contract_address,
                          "smart"
                        );
                        e.target.style.color = "green";
                        setTimeout(() => {
                          e.target.style.color = "white";
                        }, 2000);
                      }}
                    ></i>
                  </span>
                  {copySmartContract && (
                    <div className="text-center clipboard">
                      <button>
                        <span>{copySmartContract}</span>
                      </button>
                    </div>
                  )}
                  <p className="mt-auto mb-0">
                    You can see all the end-to-end transactions about our token
                    here.
                  </p>
                </div>
              </div>
              <div
                className="col-md-3 mb-4 mx-md-4 mb_s mt-5"
                data-aos="fade-up"
              >
                <div className="mv_box d-flex flex-column">
                  <span className="icon">
                    <i className="bi bi-clipboard-check"></i>
                  </span>
                  <div>
                    <h4>Audit & KYC</h4>
                    <p>
                      <a
                        href="https://contractwolf.io/projects/chainedx"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Click <span className="tgr">Here</span>
                      </a>
                    </p>
                  </div>
                  <p className="mt-auto">
                    "See Our Audit & KYC for thorough verification"
                  </p>
                </div>
              </div>
              <div
                className="col-md-3 mb-4 mr-auto ml-md-4 mb_s mt-5"
                data-aos="fade-up-left"
              >
                <div className="mv_box">
                  <span className="icon">
                    <i className="bi bi-rocket-takeoff"></i>
                  </span>
                  <h4>$CHAIN Token</h4>
                  <p>
                    {" "}
                    5% of <span className="tgr">$CHAIN</span> tokens will be
                    distributed to holders of <span className="tgr">$CHDX</span>{" "}
                    tokens & early investors.{" "}
                    <span className="tgr">$CHAIN</span> will be a governance
                    based token, allowing holders to participate in network
                    governance to strengthen and shape its future.
                  </p>
                  <a href={""} className="btn">
                    Coming Soon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="roadmap position-relative  text-center p60"
          id="token"
          data-scroll-index={4}
        >
          <div className="container">
            <h2 className="  hadding">
              Communit<span className="tgr fwb">y</span>{" "}
            </h2>
            <p className=" mb-4 mb-md-5">
              If you'd like to donate to the Devs, send ETH, BNB, or USDT here.
              Thank you for your support!
            </p>
            <div className="row  mb-4">
              <div className=" col-md-5 m-auto" data-aos="zoom-in">
                <div className="clipboard box text-left">
                  <h5 className="mt-3">Wallet Address:</h5>
                  <a
                    href={`https://bscscan.com/address/${
                      wallet && wallet[0]?.wallet_address
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p
                      className="wbb  "
                      style={{
                        color: "rgb(194, 138, 36)",
                        textDecoration: "underline",
                      }}
                    >
                      {wallet &&
                        (isMobile
                          ? wallet[0]?.wallet_address?.substring(0, 20) +
                            "..." +
                            wallet[0]?.wallet_address?.substring(
                              wallet[0]?.wallet_address?.length - 15
                            )
                          : wallet[0]?.wallet_address)}
                      {/* 0xc351155C80aCD043BD5F8FE7ffc8536af1fF9375 */}
                    </p>
                  </a>
                  <div className="text-center">
                    <button
                      style={
                        copyWallet === "Address Copied"
                          ? { color: "green", borderColor: "green" }
                          : {}
                      }
                      onClick={() => {
                        copyToClipboard(
                          wallet && wallet[0]?.wallet_address,
                          "wallet"
                        );
                      }}
                    >
                      <span>{copyWallet}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className=" col-md-4">
                <img
                  decoding="async"
                  src="img/community.svg"
                  className="img-fluid"
                  alt="Image"
                />
              </div>
            </div>
            <p>
              Our community grows stronger every day. Please follow our social
              platforms to get the most up-to-date, accurate ChainedX
              information.
            </p>
            <p>
              Using the links below, you can join our various groups alongside
              with other members of the{" "}
              <span className="tgr">ChainedXperts</span>.
            </p>
            <div className="vertical-social  mt-5" data-wow-delay="0.1s">
              <ul className="social_icon">
                {socialmedia &&
                  socialmedia?.map((item, index) => {
                    const updatedTitle = item.title.replace("bi bi-", "");
                    const formattedTitle =
                      updatedTitle.charAt(0).toUpperCase() +
                      updatedTitle.slice(1);
                    return (
                      <li key={index}>
                        <a
                          href={item?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={item.title.replace("bi bi-", "")}
                        >
                          <i className={`${item?.title}`} />
                          {/* {formattedTitle} */}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <section
          className="faq p60  position-relative bg_light"
          data-scroll-index={6}
        >
          <div className="container ">
            <h2 className="text-center hadding ">
              FAQ<span className="tgr fwb">s</span>
            </h2>
            <p className="text-center mb-4">
              Frequently asked questions (FAQ) or Questions and Answers
              (Q&amp;A), are listed questions and answers,
              <br /> all supposed to be commonly asked in some context
            </p>
            <div className="row">
              <div className="  col-md-8 m-auto">
                <div
                  className="accordion md-accordion style-2"
                  id="accordionEx"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {faq?.map((list) => {
                    return (
                      <div className="card">
                        <div
                          className="card-header"
                          role="tab"
                          id={`headingOne${list.id}`}
                        >
                          <a
                            className="collapsed"
                            data-toggle="collapse"
                            data-parent="#accordionEx"
                            href={`#collapseOne${list.id}`}
                            aria-expanded="true"
                            aria-controls={`collapseOne${list.id}`}
                          >
                            {list.question}
                          </a>
                        </div>
                        {/* Card body */}
                        <div
                          id={`collapseOne${list.id}`}
                          className="collapse "
                          role="tabpanel"
                          aria-labelledby={`headingOne${list.id}`}
                          data-parent="#accordionEx"
                        >
                          <div className="card-body">{list.answer}</div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="card">
                <div className="card-header" role="tab" id="headingOne1">
                  <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                    aria-expanded="true" aria-controls="collapseOne1">
                    1. hello909090-990-9
                  </a>
                </div>
                {/* Card body
                <div id="collapseOne1" className="collapse " role="tabpanel" aria-labelledby="headingOne1"
                  data-parent="#accordionEx">
                  <div className="card-body">
                    hellojihjkjhjkhjkhkjkjkljkljl hjhjkhjk hkjhjk hjkhjk hjkhjk hjkhkjj
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" role="tab" id="headingTwo2">
                  <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2"
                    aria-expanded="false" aria-controls="collapseTwo2">
                    2. What is ChaiendX? </a>
                </div>
                <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
                  data-parent="#accordionEx">
                  <div className="card-body"> Chainedx is a next-gen Decentralised Protocol governing all DeFi
                    application on a single Blockchain
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {video?.length > 0 && (
          <section className="faq p60  position-relative ">
            <div className="container ">
              <h2 className="text-center hadding mb-4 ">
                Our
                <span className="tgr fwb"> Videos</span>
              </h2>
              <div className="row">
                {video?.map((list) => {
                  return (
                    <div
                      className="col-md-4 mb-4"
                      data-aos="fade-up"
                      key={list.id}
                    >
                      <div className="video_box">
                        <video controls>
                          <source src={list.video} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {partnerShip?.length > 0 && (
          <section className=" p60 partners position-relative bg_light text-center">
            <div className="container-fluid ">
              <h2 className="text-center hadding mb-4 mb-md-5">
                Our
                <span className="tgr fwb"> Partners</span>
              </h2>
              <div className="align-items-center">
                <Slider {...settings}>
                  {partnerShip?.map((list) => {
                    return (
                      <div
                        style={{ margin: "0 10px", display: "inline-block" }}
                        key={list.id}
                      >
                        <img
                          className="img-fluid"
                          src={list.image}
                          alt="at0.png"
                        />
                      </div>
                    );
                  })}
                </Slider>

                {/* <div className="col-md-3 col-6">
            <img className="img-fluid" src="img/nasdaq-logo.png" alt="nasdaq-logo" />
          </div>
          <div className="col-md-3 col-6">
            <img className="img-fluid" src="img/bloomberg.png" alt="bloomberg" />
          </div>
          <div className="col-md-3 col-6">
            <img className="img-fluid" src="img/yahoo_news_logo.png" alt="yahoo_news_logo.png" />
          </div>
          <div className="col-md-3 col-6">
            <img className="img-fluid" src="img/cointelegraph.png" alt="Cointelegraph" />
          </div> */}
              </div>
            </div>
          </section>
        )}

        {team?.length > 0 && (
          <section
            className="faq p60  position-relative team_section"
            data-scroll-index={7}
          >
            <div className="container ">
              <h2 className="text-center hadding mb-4 ">
                Our <span className="tgr fwb"> Team</span>
              </h2>
              {/* <div className="row"> */}
              <Slider {...settingsForTeam}>
                {team?.map((list) => (
                  <div className="mb-1 mb-md-3" key={list.id}>
                    <div className="team_box p-md-3 p-2">
                      <img src={list.image} alt="team" />
                      <h4 className="mt-2 mt-md-3 mb-1 mb-md-2">{list.name}</h4>
                      <p className="mb-0">{list.role}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        )}
      </div>
      <Footer />
      {announcement?.length > 0 && (
        <Modal show={show} onHide={handleClose} className="home_popop">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}> </Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-0">
            <img src={announcement && announcement[0]?.image} alt="" />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
