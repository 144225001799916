import React from "react";
import { useGetDocumentQuery } from "../redux/documentApi";
export const Navbar = () => {
  const { data: record } = useGetDocumentQuery();
  let buyTokenLinkShown = false;
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="img/logo.png" alt="header-Logo" className="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="bi bi-justify" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ml-auto line">
              <li className="nav-item">
                <a className="nav-link active" href="#home" data-scroll-nav={1}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about" data-scroll-nav={2}>
                  About
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#" data-scroll-nav={3}>
                  how it work
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#" data-scroll-nav={4}>
                  token
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-scroll-nav={5}>
                  roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" data-scroll-nav={7}>
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#faq" data-scroll-nav={6}>
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact" data-scroll-nav={8}>
                  Contact
                </a>
              </li>
              <li className="nav-item ">
             

                  {record &&
                    record.map((item, index) => {
                      if (item?.type === "Document" && !buyTokenLinkShown) {
                        buyTokenLinkShown = true;
                        return (
                          <a
                            key={index}
                            className="nav-link"
                            href={item?.url}
                            target="_blank"
                          >
                            <i className="bi bi-database" /> Doc
                          </a>
                        );
                      }
                      return null;
                    })}

                  {!buyTokenLinkShown && (
                    <a
                      className="nav-link"
                      href="#"
                      target="_blank"
                    >
                      <i className="bi bi-database" /> Doc
                    </a>
                  )}
              </li>
            </ul>
            <div className="text-center p-3 p-lg-0 ml-md-5"><a className="btn " href="/staking">
              Launch App
            </a></div>
            
          </div>
        </div>
      </nav>
    </>
  );
};
