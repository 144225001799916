import { getSideBarApi } from "../constant/Api";
import { myApi } from "./api";

export const sidebarApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getSideBar: builder.query({
      query: () => ({
        url: getSideBarApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["sidebar"],
    }),
  }),
});

export const { useGetSideBarQuery } = sidebarApi;
