//===================== team ======================
export const getTeamApi="/get-team"

//================= Video =================== 
export const getVideoApi="/get-video"

//================= Faq =================== 
export const getFaqApi="/get-faq"

//================= WhitePaper =================== 
export const getWhitePaperApi="/get-whitepaper"

//================= Partnership =================== 
export const getPartnershipApi="/get-partnership"

//================= SideBar =================== 
export const getSideBarApi="/get-sidebar"

//================== announcement =================
export const getAnnouncementApi="/get-accouncement"

//================== Token sale Time ==================
export const getTokenSaletimeApi="/get-tokensaletime"


//================= aboutus =================== 
export const getAboutApi="/get-about-us"

//================= contractAddress =================== 
export const getContractApi="/get-contract-address"

//================= walletAddress =================== 
export const getWalletApi="/get-wallet-address"

//================= ai =================== 
export const getAiApi="/get-ai"

//================== deso =================
export const getDesoApi="/get-deso"

//================== gaming==================

export const getGamingApi="/get-gaming"

//================== vision==================
export const getVisionApi = "/get-vision";
//================== vision==================
export const getMissionApi = "/get-mission";
//================== roadmap==================
export const getRoadmapApi = "/get-roadmap";
//================== socialmedia==================
export const getSocialmediaApi = "/get-social-media";


//================== document==================
export const getDocumentApi = "/get-document";

