import { getContractApi, getWalletApi } from "../constant/Api";
import { myApi } from "./api";

export const contractApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getcontract: builder.query({
      query: () => ({
        url: getContractApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["contract"],
    }),
    getwallet: builder.query({
      query: () => ({
        url: getWalletApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["wallet"],
    }),
  }),
});

export const { useGetcontractQuery, useGetwalletQuery } = contractApi;
