import { getDesoApi } from "../constant/Api";
import { myApi } from "./api";

export const desoApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeso: builder.query({
      query: () => ({
        url: getDesoApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["deso"],
    }),
  }),
});

export const { useGetDesoQuery } = desoApi;