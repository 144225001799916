import React from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import { useGetDesoQuery } from "../../redux/desoApi";
export const Deso = () => {
  const { data: deso } = useGetDesoQuery();
  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          <div className="dash_box text-center pt-5 ">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={deso && deso[0]?.image}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <h3 className="mb-3 tgr">
                  {deso && deso[0]?.deso ? deso && deso[0]?.deso : "Coming Soon.."}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
