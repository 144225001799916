import { getVisionApi } from "../constant/Api";
import { myApi } from "./api";

export const visionApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getVision: builder.query({
      query: () => ({
        url: getVisionApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["vision"],
    }),
  }),
});

export const { useGetVisionQuery } = visionApi;
