import React, { useState, useEffect } from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./Button";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseEther,
  isAddress,
} from "ethers";
import { buyaddress, buyabi } from "../../constant";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import copy from "copy-to-clipboard";
import { copyUrl } from "../../constant/BaseUrl";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export const Buy = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tokenprice, settokenprice] = React.useState();
  const [tokenvalue, settokenvalue] = React.useState(0);
  const [inputamount, setinputamount] = React.useState();

  const { walletAddress } = useParams();

  const [inputamountreferral, setinputamountreferral] = useState("");
  const [show, setshow] = React.useState();
  const [copyText, setCopyText] = useState("Invite Link");
  useEffect(() => {
    if (walletAddress) {
      setinputamountreferral(walletAddress);
    }
  }, [walletAddress]);

  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + "referral/" + address);
      setCopyText("Invite Link Copied");
    }
  };
  const handlechangeReferral = async (e) => {
    setinputamountreferral(e.target.value);
  };
  const showbtn = async () => {
    if (show == true) {
      setshow(false);
    } else {
      setshow(true);
    }
  };
  const buyReferrral = async () => {
    try {
      const isValidAddress = isAddress(inputamountreferral);
      if (isValidAddress) {
        const provider = await getSignerOrProvider();
        const contract = new Contract(buyaddress, buyabi, provider);
        const transactionResponse = await contract.setReferer(
          inputamountreferral
        );
        toast.promise(transactionResponse.wait(), {
          pending: "Adding referral to your account ....",
          success: "Referral added successfully !",
          error: "Adding Referral Failed!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.dismiss();
        toast.error("This is not a valid address");
      }
    } catch (err) {
      console.error(err);
      if (err.message.includes("You already have a Referal")) {
        toast.dismiss();
        toast.error("You already have a Referral");
      }
      if (err.message.includes("You cannot refer yourself")) {
        toast.dismiss();
        toast.error("You cannot refer yourself");
      }
    }
  };
  const handleClose = () => {
    setshow(false);
    setCopyText("Invite Link");
  };
  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  const tokenPrices = async () => {
    try {
      const provider = await getSignerOrProvider();
      const contract = new Contract(buyaddress, buyabi, provider);
      const balance = await contract.tokenPrice();
      const fixedBalance = formatUnits(balance, 18);
      console.log(fixedBalance, "fixedBalance");
      settokenprice(fixedBalance);
    } catch (err) {
      console.error(err);
    }
  };
  const handlechange = async (e) => {
    const bnbamount = tokenprice * e.target.value;
    const newValue = e.target.value;
    if (newValue === "" || parseInt(newValue, 10) > 0) {
      setinputamount(newValue);
      settokenvalue(bnbamount ? bnbamount : 0);
    } else {
      setinputamount("");
    }
  };

  const buy = async () => {
    try {
      if (!isConnected) {
        toast.dismiss();
        toast.error("Please connect wallet");
        throw Error("User disconnected");
      }
      const provider = await getSignerOrProvider();
      const ethersProvider = new BrowserProvider(walletProvider);
      const balance = await ethersProvider.getBalance(address);
      const balanceInEther = formatUnits(balance.toString(), 18);
      if (balanceInEther < tokenvalue) {
        toast.error("You dont have enough BNB to Purchase");
      }
      const contract = new Contract(buyaddress, buyabi, provider);
      const transactionResponse = await contract.buyTokens(
        parseEther(inputamount),
        {
          value: parseEther(tokenvalue.toString()),
        }
      );
      toast.promise(transactionResponse.wait(), {
        pending: "Purchase in progress ....",
        success: "Purchase Successful!",
        error: "Purchase Failed!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (err) {
      if (err.message.includes("ERC20: transfer amount exceeds balance")) {
        toast.dismiss();
        toast.error("Contract balance exceed transfer");
      }

      console.error(err);
    }
  };

  const allfunc = async () => {
    await tokenPrices();
  };

  React.useEffect(() => {
    allfunc();
  }, [address]);

  return (
    <>
      <Button />
      <div className="dash_bar_man">
        <Sidebar />
        <div className="right_bar">
          <div className="row">
            <div className="col-md-5 ml-auto">
              <div className="dash_box h100">
                <div className="farm p-md-2">
                  <div className="farm_header ">
                    <div data-position="flex-between">
                      <h3>Buy CHX</h3>
                      <img
                        src="img/logo-icon.png"
                        alt="Logo"
                        className="buy_logo"
                      />
                    </div>
                  </div>
                  <div className="border_biv mt-3 mb-3"></div>
                  <div className="home_form_input deposite_input ">
                    <label htmlFor="deposit">
                      <strong>You want to buy</strong>
                    </label>
                    <div className="mb-10 input_buy    position-relative">
                      <input
                        className="pr-4"
                        type="number"
                        placeholder={0}
                        value={inputamount}
                        onChange={handlechange}
                      />
                      <strong>CHX</strong>
                    </div>
                    <div className="mb-4 mt-3" data-position="flex-between">
                      <p className="mb-0">Total BNB </p>
                      <strong>{tokenvalue}</strong>
                    </div>
                    <button onClick={buy} className="btn w100">
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 mr-auto">
              <div className="dash_box">
                <img src="img/gift.png" alt="gift" className="gift" />
                <div className="card-header2">
                  <svg
                    className="svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93h2c0 3.31 2.69 6 6 6v2zm6.36-2.3c-.45.39-1.11.35-1.51-.1l-2.14-2.27a3.978 3.978 0 00-.79-4.44l-.88-.87A3.978 3.978 0 007.97 8l-2.27-2.14a1.13 1.13 0 01-.1-1.51c.39-.45 1.06-.49 1.51-.1L9.5 7.97c.61.53 1.4.77 2.23.65.83-.12 1.56-.55 2.12-1.1l3.26 3.26c-.55.56-.98 1.29-1.1 2.12-.12.83.12 1.62.65 2.23l2.87 2.87c.38.39.44 1.04.09 1.49z" />
                  </svg>
                  <h5 className="mb-0">Referral Program</h5>
                  <span
                    className="info_icon bi bi-info-circle"
                    data-tooltip-id="referral-program"
                    data-tooltip-content="Earn 3% of your friend’s purchase and they get a 2% bonus on their first buy"
                  >
                    {" "}
                  </span>
                  <Tooltip
                    id="referral-program"
                    style={{
                      backgroundColor: "#c28a24",
                      color: "#000000",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      fontWeight: "500"
                    }}
                  />
                </div>
                <div className="border_biv mt-2 mb-3"></div>
                <div className="card-content">
                  <div className="scrollable">
                    <section>
                      <h2>How It Works:</h2>
                      <ol>
                        <li>
                          <strong>Refer a Friend:</strong> Share your wallet
                          address as a referral link.
                        </li>
                        <li>
                          <strong>Earn Rewards:</strong> Get 3% of your friend’s
                          purchase and they get a 2% bonus on their first buy.
                        </li>
                      </ol>
                    </section>
                    <section>
                      <h2>Example:</h2>
                      <p>You refer Friend A. Friend A buys 1,000 tokens.</p>
                      <ul>
                        <li>You receive 30 tokens (3%).</li>
                        <li>Friend A receives 20 extra tokens (2%).</li>
                      </ul>
                    </section>
                    <section>
                      <h2>How to Get Started:</h2>
                      <ol>
                        <li>
                          <strong>Get Your Referral Link:</strong> Copy your
                          wallet address.
                        </li>
                        <li>
                          <strong>Share Your Link:</strong> Use social media,
                          email, etc.
                        </li>
                        <li>
                          <strong>Track Your Rewards:</strong> Check your smart
                          contract interactions.
                        </li>
                      </ol>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="right_bar">
          <h3 className="mb-3 tgr">Get Referral Link</h3>
          <div className="dash_box ">
            <div className="row">
              <div className="col-md-5 mb-4 mb-md-0">
                <div className=" d-flex   btn_group ">
                  <input
                    className
                    onChange={handlechangeReferral}
                    value={inputamountreferral}
                  />

                  <button className="active" onClick={buyReferrral} href>
                    Submit Referral
                  </button>
                </div>
              </div>
              <div className=" col-auto  ml-auto">
                {show && (
                  <span className="mr-3 show-wallet-address">
                    {" "}
                    {show ? address : ""}
                  </span>
                )}
                <a className="btn btn-action btn_man_small ml-auto" href>
                  <span onClick={showbtn}>Get Referral Link </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}> </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mr-3 modal-wallet-address">
              {" "}
              {show ? address : ""}
            </div>
            <div>
              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  copyToClipboard(address);
                }}
              >
                {copyText}
              </button>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};
