import React, { useState, useEffect } from "react";
import { Sidebar } from "../../widgets/Sidebar";
import { Tooltip, TooltipWrapper } from "react-tooltip";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseEther,
  isAddress,
} from "ethers";
import { buyaddress, buyabi } from "../../constant";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";
import copy from "copy-to-clipboard";
import { copyUrl } from "../../constant/BaseUrl";
import { useParams } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
const projectId = "df0bbc8ceb676dbfc4d910a5fb4d5f58";

// 2. Set chains
const binanceTestnet = {
  chainId: 97,
  name: "BSC Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

// 3. Create a metadata object
const metadata = {
  name: "Chaindx",
  description: "chaindex decentralized dex",
  url: "https://chaindx.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [binanceTestnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});
export const Button = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletAddress } = useParams();
  const { walletProvider } = useWeb3ModalProvider();

  const [inputamountreferral, setinputamountreferral] = useState("");
  const [show, setshow] = React.useState();
  const [copyText, setCopyText] = useState("Invite Link");
  useEffect(() => {
    if (walletAddress) {
      setinputamountreferral(walletAddress);
    }
  }, [walletAddress]);
  console.log(window.location.pathname, "nnn");

  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + "referral/" + address);
      setCopyText("Invite Link Copied");
    }
  };
  const handlechangeReferral = async (e) => {
    setinputamountreferral(e.target.value);
  };
  const showbtn = async () => {
    if (!isConnected) {
      toast.dismiss();
      toast.error("Please connect wallet address");
      return false;
    }
    if (show == true) {
      setshow(false);
    } else {
      setshow(true);
    }
  };
  const buyReferrral = async () => {
    try {
      if (!isConnected) {
        toast.dismiss();
        toast.error("Please connect wallet address");
        return false;
      }
      const isValidAddress = isAddress(inputamountreferral);
      if (isValidAddress) {
        const provider = await getSignerOrProvider();
        const contract = new Contract(buyaddress, buyabi, provider);
        const transactionResponse = await contract.setReferer(
          inputamountreferral
        );
        toast.promise(transactionResponse.wait(), {
          pending: "Adding referral to your account ....",
          success: "Referral added successfully !",
          error: "Adding Referral Failed!",
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.dismiss();
        toast.error("This is not a valid address");
      }
    } catch (err) {
      console.error(err);
      if (err.message.includes("You already have a Referal")) {
        toast.dismiss();
        toast.error("You already have a Referral");
      }
      if (err.message.includes("You cannot refer yourself")) {
        toast.dismiss();
        toast.error("You cannot refer yourself");
      }
    }
  };
  const handleClose = () => {
    setshow(false);
    setCopyText("Invite Link");
  };
  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };

  return (
    <>
      {" "}
      <div className="top_bar">
        <div className="d-sm-flex align-items-center   justify-content-end">
          {window.location.pathname === "/staking" ? (
            ""
          ) : (
            <>
              {show && (
                <span className="mr-3 show-wallet-address">
                  {show ? address : ""}
                </span>
              )}
              {/* {isConnected ? ( */}
              <a className="btn btn-action btn_man_small" href>
                <span
                  onClick={showbtn}
                  className="d-flex align-items-center gap-2"
                >
                  Get Referral Link{" "}
                  <span
                    className="info_icon bi bi-info-circle text-sm"
                    data-tooltip-id="referralLink"
                    data-tooltip-content="This is your unique referral link to share with friends"
                  ></span>
                  <Tooltip
                    id="referralLink"
                    place="bottom"
                    style={{
                      backgroundColor: "#c28a24",
                      color: "#000000",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      filter: "drop-shadow(0px 0px 10px #000)",
                    }}
                    content="This is your unique referral link to share with friends."
                  />
                </span>
              </a>
              {/* ) : (
                ""
              )} */}

              {/* {isConnected ? ( */}
              <div className="d-sm-flex mobile_top-bar">
                <div className=" d-flex   btn_group ml-sm-2 ml-md-4 mt-3 mt-sm-0">
                  <input
                    className
                    onChange={handlechangeReferral}
                    value={inputamountreferral}
                    placeholder="Enter referral code"
                  />

                  <button
                    className="active d-flex align-items-center gap-2"
                    onClick={buyReferrral}
                    id="referralButton"
                  >
                    Submit Referral{" "}
                    <span
                      className="info_icon bi bi-info-circle text-sm"
                      data-tooltip-id="referralButton"
                      data-tooltip-content="This is your unique referral link to share with friends"
                    ></span>
                    <Tooltip
                      id="referralButton"
                      place="bottom"
                      style={{
                        backgroundColor: "#c28a24",
                        color: "#000000",
                        maxWidth: "300px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        filter: "drop-shadow(0px 0px 10px #000)",
                      }}
                      content="Enter your friend's wallet address to submit a referral"
                    />
                  </button>
                </div>
              </div>
              {/* ) : (
                ""
              )} */}
            </>
          )}

          <div className=" ml-2 mobile_btn_t  ">
            <w3m-button balance="hide" />
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title> </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="mr-3 modal-wallet-address">
                {" "}
                {show ? address : ""}
              </div>
              <div>
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    copyToClipboard(address);
                  }}
                >
                  {copyText}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};
