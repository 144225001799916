import React from "react";
import { Navbar } from "../../widgets/Navbar";
import { Footer } from "../../widgets/Footer";
import { useGetVisionQuery } from "../../redux/visionApi";
export const Vision = () => {
  const { data: vision } = useGetVisionQuery();

  return (
    <>
      <Navbar />
      <div className="in_page">
        <div className="container">
          {vision && vision[0]?.vision ? (
            <div dangerouslySetInnerHTML={{ __html: vision[0]?.vision }}></div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
