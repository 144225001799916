import React from "react";
import { Navbar } from "../../widgets/Navbar";
import { Footer } from "../../widgets/Footer";
import { useGetDocumentQuery } from "../../redux/documentApi";

export const Document = () => {
  const { data: record } = useGetDocumentQuery();
  return (
    <>
      <Navbar />
      <section className="outer-document">
        <div className="container">
          <div className="text-head">
            <h2 className=" hadding mb-3  text-center">
              {record && record[0]?.heading}
            </h2>
          </div>
          <div className="row align-items-center">
          <div className="col-md-6">
              <div className="img-outer text-center">
                <img
                  src={record && record[0]?.image}
                  className=" img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-outer mb-3">
                <p>{record && record[0]?.text}</p>
                <p>
                  <a href={record && record[0]?.url} className="url">
                    {record && record[0]?.url}
                  </a>
                </p>
              </div>
            </div>
         
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
