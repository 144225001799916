import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { Ai } from "./components/pages/Ai";
import { Buy } from "./components/pages/Buy";
import { Dashboard } from "./components/pages/Dashboard";
import { Gaming } from "./components/pages/Gaming";
import { Deso } from "./components/pages/Deso";
import { Refferal } from "./components/pages/Refferal";
import { Community } from "./components/pages/Community";
import { ToastContainer } from "react-toastify";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import "react-toastify/dist/ReactToastify.css";
import { Mission } from "./components/pages/Mission";
import { Vision } from "./components/pages/Vision";
import { CommingSoon } from "./components/pages/CommingSoon";
import { Document } from "./components/pages/Document";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ai" element={<Ai />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/staking" element={<Dashboard />} />
          <Route path="/gaming" element={<Gaming />} />
          <Route path="/deso" element={<Deso />} />
          <Route path="/referral/:walletAddress" element={<Refferal />} />
          <Route path="/referral" element={<Refferal />} />
          <Route path="/community" element={<Community />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/comming-soon" element={<CommingSoon />} />
          <Route path="/document" element={<Document />} />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
